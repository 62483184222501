<template>
  <div>
    <h5 class="pl-1 font-weight-bold my-2">Health Checklist</h5>
    <div class="row">
      <div class="col-12">
        <medical-nav></medical-nav>
      </div>
      <div class="col-12">
        <table class="table table-sm table-bordered small">
          <thead>
            <tr>
              <td class="align-middle text-center font-weight-bold">Passcode</td>
              <td class="align-middle text-center font-weight-bold">Date Generated</td>
              <td class="align-middle text-center font-weight-bold">Date Accessed</td>
              <td class="align-middle text-center font-weight-bold">Date Submitted</td>
              <td class="align-middle text-center font-weight-bold">Days to Expire</td>
              <td class="align-middle text-center font-weight-bold">Action</td>
            </tr>
          </thead>
          <tbody>
          <template v-if="crewDocuments">
            <tr v-for="healthDeclarations in crewDocuments">
              <td>{{healthDeclarations.passcode}}</td>
              <td>{{healthDeclarations.createdAt}}</td>
              <td>{{healthDeclarations.loggedAt}}</td>
              <td>{{healthDeclarations.submittedAt}}</td>
              <td class="text-center">{{healthDeclarations.daysToExpire ? healthDeclarations.daysToExpire.toUpperCase() : ''}}</td>
              <td class="text-center">
                <button class="btn btn-secondary btn-xs"
                        data-target="#checkList"
                        data-toggle="modal"
                        @click="viewHealthDeclaration(healthDeclarations)"
                        :disabled="!healthDeclarations.hasFile">
                  View
                </button>
              </td>
            </tr>
          </template>

          </tbody>
        </table>

        <div class="modal fade"  data-backdrop="static" data-keyboard="false" id="checkList" tabindex="-1" aria-labelledby="crewDocument" aria-hidden="true">
          <div class="modal-dialog modal-xl">
            <div class="modal-content" style="height:auto">
              <div class="modal-header">
                <h5 class="modal-title" id="crewIncidentBehaviorTitle">VIEW CHECKLIST</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-12" style="height: 77vh">
                    <template v-if="checkListFile">
                      <iframe style="height: inherit; width: 100%" :src="checkListFile"></iframe>
                    </template>
                    <div v-else class="alert alert-danger">
                      NO FILE FOUND
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MedicalNav from "@/components/crew/document/medical/MedicalNav";
import {mapActions, mapGetters} from "vuex";
import {CrewService} from "@/services/CrewService";
import {FileService} from "@/services/FileService";

export default {
  name: "Checklist",
  components:{MedicalNav},
  data(){
    return {
      checkListFile:''
    }
  },
  async created() {
    const params={
      cms_id : this.$route.params.id
    }
    await this.getCrewHealthDeclarations(params)
  },
  methods:{
    ...mapActions(['getCrewHealthDeclarations']),
   async viewHealthDeclaration(healthDeclaration){
      this.checkListFile='';
      const checkListFile= await CrewService.getHealthDeclationFile({healthDeclaration: healthDeclaration.id});
      this.checkListFile=checkListFile ? FileService.base64FileToBlobUrl(checkListFile.file) : false;
    }
  },
  computed:{
    ...mapGetters(['crewDocuments'])
  }
}
</script>

<style scoped>

</style>
